export default {
  // Set this to the base URL of your sample server, such as 'https://your-app-name.herokuapp.com'.
  // Do not include the trailing slash. See the README for more information:
  SAMPLE_SERVER_BASE_URL: 'http://YOUR-SERVER-URL',
  // OR, if you have not set up a web server that runs the learning-opentok-php code,
  // set these values to OpenTok API key, a valid session ID, and a token for the session.
  // For test purposes, you can obtain these from https://tokbox.com/account.
  API_KEY: '46144082',
  SESSION_ID: '2_MX40NjE0NDA4Mn5-MTUzMDAwMjcxMTA3M35rRXF3SU5XQTJDLzQwNERsOWtLdnhUZjR-fg',
  TOKEN: 'T1==cGFydG5lcl9pZD00NjE0NDA4MiZzaWc9MWEzYjM0NThhMWE1MzlkNjg5ZDA4M2NhNTM5NTI2ODJkNDAyYmZiOTpzZXNzaW9uX2lkPTJfTVg0ME5qRTBOREE0TW41LU1UVXpNREF3TWpjeE1UQTNNMzVyUlhGM1NVNVhRVEpETHpRd05FUnNPV3RMZG5oVVpqUi1mZyZjcmVhdGVfdGltZT0xNTMwMDAyNzQ5Jm5vbmNlPTAuMzM2MTk2NTExMjYyMzc5MjYmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTUzMjU5NDc0NyZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ=='
};
