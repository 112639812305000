import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

userForm: FormGroup;
  items: any;
  user = {
    username: '',
     email: '',
     password: '',
     age: ''
   };

  constructor(private fb: FormBuilder, private af: AngularFireDatabase, public auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.buildForm();
  }

  register() {

    console.log("values : " +JSON.stringify(this.user));
  }

buildForm(): void {
  this.userForm = this.fb.group({
    'email': ['', [
        Validators.required,
        Validators.email
      ]
    ],
    'password': ['', [
      Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
      Validators.minLength(6),
      Validators.maxLength(25)
    ]
  ],
  'username': ['', [
  Validators.required
  ]
],
'age': ['', [
  Validators.required
]
]
  });

  this.userForm.valueChanges.subscribe(data => this.onValueChanged(data));
  this.onValueChanged(); // reset validation messages
}

onValueChanged(data?: any) {
   if (!this.userForm) { return; }
   const form = this.userForm;
   for (const field in this.formErrors) {
     // clear previous error message (if any)
     this.formErrors[field] = '';
     const control = form.get(field);
     if (control && control.dirty && !control.valid) {
       const messages = this.validationMessages[field];
       for (const key in control.errors) {
         this.formErrors[field] += messages[key] + ' ';
       }
     }
   }
 }

 formErrors = {
   'email': '',
   'password': '',
   'username': '',
   'age': ''
 };

 validationMessages = {
   'email': {
     'required':      'Email is required.',
     'email':         'Email must be a valid email'
   },
   'password': {
     'required':      'Password is required.',
     'pattern':       'Password must be include at one letter and one number.',
     'minlength':     'Password must be at least 4 characters long.',
     'maxlength':     'Password cannot be more than 40 characters long.',
   },
   'username': {
     'required':      'Username is required.'
   },
   'age': {
     'required':      'Age is required.'
   }
 };

 signUpWithEmail() {
   console.log('password' +this.user.password);
   this.auth.signUpRegular(this.user.email, this.user.password)
     .then((res) => {
       // console.log('success ' +res.uid);
       // this.pageTitle='Successfully registered';
       this.pushuser();
       this.router.navigate(['/login']);
       // this.authState = user
     })
     .catch(error => {
       console.log('Error :' +error);
         // this.pageTitle='Registration Failed';
       // throw error
     });
 }

 pushuser(){

   // this.member = new User(this.user.id, this.user.name, this.user.username, this.user.email, this.user.password);
 this.items = this.af.list("/from_site");
  this.items.push(this.user);


 }

}
