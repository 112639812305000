import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  lat: number = 12.9657821;
  lng: number = 77.70248470000001;

  constructor() { }

  ngOnInit() {
  }

}
