import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class EventService {

  @Output() searchEvent = new EventEmitter<string>();
  @Output() excelEvent = new EventEmitter<any>();
  @Output() crystalEvent = new EventEmitter<any>();

 constructor() { }

 sendSearch(text: any) {
   this.searchEvent.emit(text);
   console.log(text);
 }

 sendCrystal() {
   this.crystalEvent.emit();
   console.log("CRystallllllllllll");
 }

 excelExport() {
   this.excelEvent.emit();
   console.log('Excel');
 }

}
