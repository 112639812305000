import { Component, ChangeDetectorRef, AfterViewChecked, ElementRef, ViewChild, OnInit } from '@angular/core';
import {AdmindataService} from '../service/admindata.service';
import { OpentokService } from '../opentok.service';
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import { FirebaseListObservable } from "angularfire2/database-deprecated";

import { AngularFireAuth } from 'angularfire2/auth';
import { AuthService } from '../service/auth.service'
import {UserService} from '../user.service'
import * as OT from '@opentok/client'

@Component({
  selector: 'app-textchat',
  templateUrl: './textchat.component.html',
  styleUrls: ['./textchat.component.css']
})
export class TextchatComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  public isCollapsed = false;
  items: any;;
  msgs:Array<String> = new Array();
  disname:string;
  disid:string;
  msgVal:string;
  logged: Boolean;

// admins:Array <String>= new Array();

  constructor(private _firebaseAuth: AngularFireAuth, private ref: ChangeDetectorRef, private opentokService: OpentokService,  private userService: UserService, private authService: AuthService, private af: AngularFireDatabase) {
    this.changeDetectorRef = ref;

    this._firebaseAuth.authState.subscribe(res => {
    if (res && res.uid) {
    this.logged=true;
    console.log('UID' +res.email);
    console.log('RES' +JSON.stringify(res));
    this.disname=res.email;
    this.disid=res.uid;
  }

});

  this.userService.getMsg().subscribe(val => {
        for (var key in val) {
          console.log(val[key]);
            this.msgs.push(val[key]);
        }
          console.log("Array " +this.msgs);
       });
  }
  ngAfterViewChecked() {
          this.scrollToBottom();
      }


  ngOnInit() {
}


chatSend(theirMessage: string) {
  this.items = this.af.list("Users/-LGA5Pu4AZb38_wwkgcn/messages");
   // this.items.push([this.user]);
      this.items.push({ text: theirMessage, senderName: this.disname, senderId: this.disid});
      this.msgVal = '';

      this.userService.getMsg().subscribe(val => {
        for (var key in val) {
          console.log(val[key]);
            this.msgs.push(val[key]);
        }
        // console.log("Array " +this.msgs);

       });

  }
  scrollToBottom(): void {
     try {
         this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
     } catch(err) { }
 }

}
