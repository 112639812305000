import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signal',
  templateUrl: './signal.component.html',
  styleUrls: ['./signal.component.css']
})
export class SignalComponent implements OnInit {

    constructor() { }

    ngOnInit() {
      // this.loadScript('src/assets/dist/js/app.js');
    }


      // public loadScript(url) {
      //     console.log('preparing to load...')
      //     let node = document.createElement('script');
      //     node.src = url;
      //     node.type = 'text/javascript';
      //     document.getElementsByTagName('head')[0].appendChild(node);
      //  }


  }
