import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable, Subject, ReplaySubject } from 'rxjs';
// import { from, of, range } from 'rxjs/create';
import { map, filter, switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AdmindataService {

// admns:Array<String> = new Array();

  constructor(private http: Http) {
  }

  getAdmin(): Observable<any> {

    console.log("randamathe");

     return this.http.get("https://healthtech1-c5be3.firebaseio.com/users.json")
     .pipe(map((res: Response) => res.json()))
     // .catch((error: any) => Observable.throw(error.json().error || 'Server error')));
//      .pipe(map(res => { res.json();
//           for (var key in res.json()) {
//     this.admns.push(JSON.stringify(res.json()[key]));
// }
//   console.log("Array "+this.admns);
//
//         return this.admns;
//       }
//     ) )// or any other operator

      // .subscribe(res => console.log(res));

  }

  getUser(): Observable<any> {

    console.log("randamathe");

     return this.http.get("https://healthtech1-c5be3.firebaseio.com/from_site.json")
     .pipe(map((res: Response) => res.json()))
     // .catch((error: any) => Observable.throw(error.json().error || 'Server error')));
//      .pipe(map(res => { res.json();
//           for (var key in res.json()) {
//     this.admns.push(JSON.stringify(res.json()[key]));
// }
//   console.log("Array "+this.admns);
//
//         return this.admns;
//       }
//     ) )// or any other operator

      // .subscribe(res => console.log(res));

  }


  // getAdmin() {
  //    return this.http.get("https://healthtech1-c5be3.firebaseio.com/users.json")
  //       .pipe(map((res: Response) => res.json()))
  //       .subscribe(res => console.log(res));
  // }


}
