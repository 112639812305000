import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crystal',
  templateUrl: './crystal.component.html',
  styleUrls: ['./crystal.component.css']
})
export class CrystalComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
