
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
// import { Observable } from 'rxjs/Rx';
// import { User } from '../models/user';
import { map, filter, switchMap } from 'rxjs/operators';


@Injectable()

export class UserService {
   constructor(private http: Http) {
   }



   getMsg(){

      return this.http.get("https://healthtech1-c5be3.firebaseio.com/Users/-LGA5Pu4AZb38_wwkgcn/messages.json")
       .pipe(map((res: Response) => res.json()))
   }


}
