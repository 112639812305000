import { ModuleWithProviders }  from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppComponent} from './app.component'
import {AboutComponent} from './about/about.component'
import {HomeComponent} from './home/home.component'
import { CrystalComponent } from './crystal/crystal.component'
import { LoginComponent } from './login/login.component'
import { ContactComponent } from './contact/contact.component'
import { RequestComponent } from './request/request.component'
import { AdminsComponent } from './admins/admins.component'
import { VideochatComponent } from './videochat/videochat.component'
import { PublisherComponent } from './publisher/publisher.component'
import { SubscriberComponent } from './subscriber/subscriber.component'
import { ChatComponent } from './chat/chat.component'
import { TextchatComponent } from './textchat/textchat.component'
import { RegisterComponent } from './register/register.component';
import { UsersComponent } from './users/users.component';



import {provideRoutes} from '@angular/router';
// Route Configuration
export const router: Routes = [
    { path: '', redirectTo: 'home',pathMatch:'full' },
    { path: 'home', component: HomeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'crystal', component: CrystalComponent },
    { path: 'login', component: LoginComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'request', component: RequestComponent },
    { path: 'admins', component: AdminsComponent },
    { path: 'publisher', component: PublisherComponent },
    { path: 'subscriber', component: SubscriberComponent },
    { path: 'videochat', component: VideochatComponent },
    { path: 'chat', component: ChatComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'text', component: TextchatComponent },
    { path: 'users', component: UsersComponent }

  ];

  // Deprecated provide;
  // export const APP_ROUTER_PROVIDERS = [
  //   provideRouter(routes)
  // ];

  export const routes: ModuleWithProviders = RouterModule.forRoot(router);
