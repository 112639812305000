import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';

import {EventService} from '../service/event.service'
// import {map} from 'rxjs/operators';
// import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [NgbCarouselConfig]
})
export class HomeComponent implements OnInit {
  // images: Array<string>;
  public isCollapsed = false;
  public issCollapsed = true;
  public istCollapsed = true;

    constructor(config: NgbCarouselConfig, private eventService:EventService) {
      // customize default values of carousels used by this component tree
      config.interval = 3000;
      config.wrap = false;
      config.keyboard = false;
    }

    ngOnInit() {}


}
