// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: true,
//   firebase: {
//     apiKey: 'AIzaSyDCoRvQ1h6Cxpl2KXzR1vA0x0_OFUDswvU',
//     authDomain: 'healthtech-2244.firebaseapp.com',
//     databaseURL: 'https://healthtech-2244.firebaseio.com',
//     projectId: 'healthtech-2244',
//     storageBucket: 'healthtech-2244.appspot.com',
//     messagingSenderId: '629878738062'
//   }
// };


export const environment = {
  production: true,
  firebase: {
  //   apiKey: "AIzaSyCw7efyYP9y0-vO6dvzanraLW1bkFAQws8",
  // authDomain: "sample-d44f5.firebaseapp.com",
  // databaseURL: "https://sample-d44f5.firebaseio.com",
  // projectId: "sample-d44f5",
  // storageBucket: "sample-d44f5.appspot.com",
  // messagingSenderId: "128031937804"
  apiKey: 'AIzaSyBLgPyjEAc3qIpbRF-37nsNsC4ZtQJRcUg',
    authDomain: 'healthtech1-c5be3.firebaseapp.com',
    databaseURL: 'https://healthtech1-c5be3.firebaseio.com',
    projectId: 'healthtech1-c5be3',
    storageBucket: 'healthtech1-c5be3.appspot.com',
    messagingSenderId: '531650070432'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
