import { Injectable } from '@angular/core';

@Injectable()
export class DataTransferService {

  constructor(

  ) { }

  private data;

  setData(data){
    this.clearData();
    this.data = data;
  }

  getData(){
    let temp = this.data;

    return temp;
  }

  clearData(){
    this.data = undefined;
  }

}
