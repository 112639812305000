import { Component, OnInit } from '@angular/core';
import {DataTransferService} from '../service/datatransfer.service'

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

data:string;

  constructor(private dataService:DataTransferService) {
  this.data = this.dataService.getData();
  // console.log("haha " +data);
 }

  ngOnInit() {
  }

}
