import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
 import { HttpClientModule, HttpClient } from '@angular/common/http';
 import { AngularFontAwesomeModule } from 'angular-font-awesome';
 import { AgmCoreModule } from '@agm/core';
 import { CarouselModule } from 'ngx-bootstrap';

 import {AngularFireModule} from 'angularfire2';
 import {AngularFireDatabaseModule} from 'angularfire2/database';
 import { AngularFireAuthModule } from 'angularfire2/auth';


import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { CrystalComponent } from './crystal/crystal.component';

import { routes } from './app.router';
import { LoginComponent } from './login/login.component';
import { ContactComponent } from './contact/contact.component';
import {EventService} from './service/event.service';
import {AdmindataService} from './service/admindata.service';
import { AuthService } from './service/auth.service';
import { DataTransferService } from './service/datatransfer.service';
import { RequestComponent } from './request/request.component'
import { environment } from '../environments/environment';
import { AdminsComponent } from './admins/admins.component';
import { RegisterComponent } from './register/register.component';
import { VideochatComponent } from './videochat/videochat.component';
import { SubscriberComponent } from './subscriber/subscriber.component';
import { PublisherComponent } from './publisher/publisher.component';
import { OpentokService } from './opentok.service';
import {UserService} from './user.service'

import { ChatComponent } from './chat/chat.component';
import { SignalComponent } from './signal/signal.component';
import { TextchatComponent } from './textchat/textchat.component';
import { UsersComponent } from './users/users.component';





@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HomeComponent,
    CrystalComponent,
    LoginComponent,
    ContactComponent,
    RequestComponent,
    AdminsComponent,
    RegisterComponent,
    VideochatComponent,
    SubscriberComponent,
    PublisherComponent,
    ChatComponent,
    SignalComponent,
    TextchatComponent,
    UsersComponent

  ],
  imports: [
    CarouselModule.forRoot(),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    routes,
    NgbModule,
    HttpClientModule,
    HttpModule,
    AngularFontAwesomeModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDHJ8zNqeiy2EfY9Y5HLQp22elIdFYoVd0'
    })
  ],
  providers: [EventService, AdmindataService, AuthService, OpentokService, UserService, DataTransferService],
  bootstrap: [AppComponent]
})
export class AppModule { }
