import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
// import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthService {
  private user: any;
  logged:Boolean;

constructor(private _firebaseAuth: AngularFireAuth, private router: Router) {
      // this.user = _firebaseAuth.authState;
      this._firebaseAuth.authState.subscribe(res => {
  if (res && res.uid) {
    this.logged=true;
    console.log('user is logged in');

  } else {
    console.log('user not logged in');
    this.logged=false;
  }
});
  }



  signInRegular(email, password) {

     const credential = firebase.auth.EmailAuthProvider.credential( email, password );

  return this._firebaseAuth.auth.signInWithEmailAndPassword(email, password);

  }

  signUpRegular(email, password) {
    console.log(email+ ' ' +password);
    return this._firebaseAuth.auth.createUserWithEmailAndPassword(email, password)
    .then(function(user){
      if(user && user.emailVerified === false){
        user.sendEmailVerification().then(function(){
          console.log("email verification sent to user");
        });
      }
    }).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;

      console.log(errorCode, errorMessage);
    });


  // Error Code
}

signOut(): void {
  this._firebaseAuth.auth.signOut().then(function() {

  console.log("adichu monee");
}).catch(function(error) {
  console.log("not working mone");
});

this.router.navigate(['/login']);

}

 loggedOrnot(): Boolean {
    return this.logged;
 }

  }

//  userdetails(){
//   user = _firebaseAuth.auth().currentUser;
//   // console.log(user);
//
//   if (user != null) {
//     // name = user.displayName;
//     email = user.email;
//     // photoUrl = user.photoURL;
//     // emailVerified = user.emailVerified;
//     uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
//                      // this value to authenticate with your backend server, if
//              // you have one. Use User.getToken() instead.
//     console.log(email+' '+uid);
//   }
// }

// *ngIf="errorMessage.length > 0"
