import { Component, Inject, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
 import { DOCUMENT } from "@angular/platform-browser";
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {EventService} from './service/event.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [NgbCarouselConfig]
})
export class AppComponent {
  public fixed: boolean = false;
  title = 'app';
  constructor(@Inject(DOCUMENT) private doc: Document,  private eventService:EventService, private router:Router) {

    this.eventService.crystalEvent.subscribe((data) => this.exportCrystal(), (err) => console.log(err));

    this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });

  }

  @HostListener("window:scroll", [])
        onWindowScroll() {
           let num = this.doc.documentElement.scrollTop;
           console.log("value is = " +num);
           if ( num > 90 ) {
               this.fixed = true;
           }else if (this.fixed && num < 90) {
               this.fixed = false;
           }
        }

        ngOnInit() {}

        exportCrystal = function() {
            console.log("Rand vattam");
            this.router.navigateByUrl('crystal');
            window.scrollTo(0, 0)
        }

}
