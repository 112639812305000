import { Component, ChangeDetectorRef, AfterViewChecked, ElementRef, OnInit } from '@angular/core';
import { OpentokService } from '../opentok.service';
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import { AuthService } from '../service/auth.service'
import {UserService} from '../user.service'
import * as OT from '@opentok/client';

@Component({
  selector: 'app-videochat',
  templateUrl: './videochat.component.html',
  styleUrls: ['./videochat.component.css'],
  providers: [ OpentokService ]
})
export class VideochatComponent implements OnInit {
  // @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  // title = 'Angularfirebase';
  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  public isCollapsed = false;
  // items: FirebaseListObservable<any>;
  // msgs:Array = new Array();

  constructor(private ref: ChangeDetectorRef, private opentokService: OpentokService,  private userService: UserService,  private authService: AuthService, private af: AngularFireDatabase) {
    this.changeDetectorRef = ref;

  // this.userService.getMsg().subscribe(val => {
  //       for (var key in val) {
  //         console.log(val[key]);
  //           this.msgs.push(val[key]);
  //       }
  //         console.log("Array " +this.msgs);
  //
  //      });
  // }
  // ngAfterViewChecked() {
  //         this.scrollToBottom();
  //     }
}
  ngOnInit () {
    this.opentokService.initSession().then((session: OT.Session) => {
      this.session = session;
      this.session.on('streamCreated', (event) => {
        let subscriberOptions = {
          insertMode: 'append',
          width: '100%',
          height: '100%'
        };
        let publisherOptions = {
          insertMode: 'append',
          width: '100%',
          height: '100%'
        };
        this.streams.push(event.stream);
        this.changeDetectorRef.detectChanges();
      });

      this.session.on('streamDestroyed', (event) => {
        const idx = this.streams.indexOf(event.stream);
        if (idx > -1) {
          this.streams.splice(idx, 1);
          this.changeDetectorRef.detectChanges();
        }
      });
    })
    .then(() => this.opentokService.connect())
    .catch((err) => {
      console.error(err);
      alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');
    });

  }



}
