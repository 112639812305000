import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.css']
})
export class RequestComponent implements OnInit {

  lat: number = 12.9657821;
  lng: number = 77.70248470000001;

  constructor() { }

  ngOnInit() {
  }

}
