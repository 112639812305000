import { Component, OnInit } from '@angular/core';
import {AdmindataService} from '../service/admindata.service';
import {DataTransferService} from '../service/datatransfer.service'
import {AuthService} from '../service/auth.service';
import { Http, Response } from '@angular/http';
import { Observable, Subject, ReplaySubject } from 'rxjs';
// import { from, of, range } from 'rxjs/create';
import { map, filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

// admins: Array="";
logged:Boolean;
admins:Array<String> = new Array();
  constructor(private dataService:DataTransferService, private admindataService: AdmindataService, private authService: AuthService) {

    console.log("adyatheth " +this.admins);

    this.admindataService.getUser().subscribe(val => {
      for (var key in val) {
          this.admins.push(val[key]);
      }
        // console.log(this.admins);

     });
    // this.admins.subscribe(val => this.admin=val);
    // console.log("Vanneeee " +this.admins);

    // this.logged=this.authService.loggedOrnot();
    // console.log("loged in" +this.logged);

   }

  ngOnInit() {
  }

logOut(){
  this.authService.signOut();
}

toChat=function(user: string){
//console.log(user.phone)
console.log("hi" +user);
this.dataService.setData(user);
// this.router.navigateByUrl('/chat');
}


}
